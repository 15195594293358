<template>
    <div class="col pt-4 pl-4 overflow-auto scroll-none" style="height:calc(100vh - 81px);">
        <div v-for="(data, index) in premiosLista" :key="index" class="row mx-0 align-items-center pr-md-0 pr-sm-0 pr-0 mb-5">
            <p v-if="data.nombre != null" class="col px-0 f-28 text-general titles f-600">
                {{ data.nombre }}
            </p>
            <div v-if="data.contador > 5" class="d-middle cr-pointer" @click="categoriaIr(data.productos)">
                <span class="f-20 text-purple text-arrow">Vér más</span>
                <i class="icon-right-open mt-1 f-17 text-purple mr-2" />
            </div>
            <div class="col-12" />
            <div class="like-bar mb-2" />
            <div class="col-12 px-0 scroll-none overflow-auto">
                <div class="mt-2" style="display:inline-flex;">
                    <card-premio v-for="datas in data.productos.slice(0, 5)" :key="datas.id" :info-premio="datas" :tipo-pago="moneda" class="mr-xl-3 mr-lg-3 mr-md-3 mr-sm-3 mr-2" />
                    <card-premio v-if="data.contador > 5" class="mr-xl-3 mr-lg-3 mr-md-3 mr-sm-3 mr-2" :contador="data.contador" :id-categoria="index" @verCategoriaId="categoriaIrId" />
                </div>
            </div>
        </div>
        <template v-if="premiosLista.length === 0">
            <div class="d-middle-center" style="width:100%;height:55vh;">
                <div class="col-auto">
                    <div class="row mx-0 j-center">
                        <img :src="funImagenGeneral(26)" width="180" height="180" />
                    </div>
                    <p class="text-center mt-3">
                        No tenemos premios disponibles en este momento
                    </p>
                    <div class="row mx-0 mt-xl-4 mt-lg-4 mt-md-4 mt-sm-3 mt-2 mt-2 mb-md-2">
                        <div class="col-12 px-2">
                            <div class="btn-general" @click="$router.push({name:'home.tienda'})">
                                Ir a la Tienda
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    props: {
        moneda: {
            type: Boolean,
            default: false
        },
        premiosLista: {
            type: Array,
            required: true,
        },
        
    },
    data(){
        return {
            id_tienda: '',
            premiosCategoria: {}
        }
    },
    methods: {
        categoriaIr(item){
            this.$emit("verCategoria", item);  
        },
        categoriaIrId(idCategoria){
            this.premiosCategoria = this.premiosLista[idCategoria]
            this.categoriaIr(this.premiosCategoria.productos)
        }

    }

}

</script>
<style lang="scss" scoped>
@media (min-width: 300px) and (max-width: 1024px){
    .col.pt-4.pl-4{
        height: calc(100vh - 99px) !important;
    }
}
</style>